/**
 *    SPDX-License-Identifier: Apache-2.0
 */

import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import FontAwesome from 'react-fontawesome';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Table, Card, CardBody, CardTitle } from 'reactstrap';
import JSONTree from 'react-json-tree';
import { transactionType } from '../types';
import Modal from '../Styled/Modal';
/* eslint-disable */
const readTheme = {
	base00: '#f3f3f3',
	base01: '#2e2f30',
	base02: '#515253',
	base03: '#737475',
	base04: '#959697',
	base05: '#b7b8b9',
	base06: '#dadbdc',
	base07: '#fcfdfe',
	base08: '#e31a1c',
	base09: '#e6550d',
	base0A: '#dca060',
	base0B: '#31a354',
	base0C: '#80b1d3',
	base0D: '#3182bd',
	base0E: '#756bb1',
	base0F: '#b15928'
};
const writeTheme = {
	base00: '#ffffff',
	base01: '#2e2f30',
	base02: '#515253',
	base03: '#737475',
	base04: '#959697',
	base05: '#b7b8b9',
	base06: '#dadbdc',
	base07: '#fcfdfe',
	base08: '#e31a1c',
	base09: '#e6550d',
	base0A: '#dca060',
	base0B: '#31a354',
	base0C: '#80b1d3',
	base0D: '#3182bd',
	base0E: '#756bb1',
	base0F: '#b15928'
};
/* eslint-enable */
const styles = theme => ({
	listIcon: {
		color: '#ffffff',
		marginRight: 20
	},
	JSONtree: {
		'& ul': {
			backgroundColor: 'transparent !important',
			color: '#fff'
		}
	},
	readset_null: {
		display: 'none'
	}
});

const reads = {
	color: '#2AA233'
};
const writes = {
	color: '#DD8016'
};

export class TransactionView extends Component {

	constructor(props) {
		super(props);
		this.state = {
			from: '',
			to: '',
			block_num:0,
			amount:0,
		};
	}

	handleClose = () => {
		const { onClose } = this.props;
		onClose();
	};

	formatDate(isoString) {
		const date = new Date(isoString);
		const year = date.getFullYear();
		const month = String(date.getMonth() + 1).padStart(2, '0'); // 月份从0开始，所以需要加1
		const day = String(date.getDate()).padStart(2, '0');
		const hours = String(date.getHours()).padStart(2, '0');
		const minutes = String(date.getMinutes()).padStart(2, '0');
		const seconds = String(date.getSeconds()).padStart(2, '0');
		
		return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
	}
	formatUnits(value, decimals) {
		let display = value.toString();
	
		const negative = display.startsWith('-');
		if (negative) display = display.slice(1);
	
		// 将数值部分和小数部分分开
		display = display.padStart(decimals + 1, '0'); // 加 1 处理整数部分为 0 的情况
	
		let integer = display.slice(0, display.length - decimals);
		let fraction = display.slice(display.length - decimals);
	
		fraction = fraction.replace(/(0+)$/, ''); // 去掉尾随的 0
	
		// 如果整数部分为空，设置为 "0"
		if (!integer) {
			integer = '0';
		}
	
		// 返回带有负号（如果有）、整数部分和小数部分的字符串
		return `${negative ? '-' : ''}${integer}${fraction ? `.${fraction}` : ''}`;
	}
	// 将big根据位数转换
	// formatUnits(value,decimals) {
	// 	let display = value.toString()
	  
	// 	const negative = display.startsWith('-')
	// 	if (negative) display = display.slice(1)
	  
	// 	display = display.padStart(decimals, '0')
	  
	// 	let [integer, fraction] = [
	// 	  display.slice(0, display.length - decimals),
	// 	  display.slice(display.length - decimals),
	// 	]
	// 	fraction = fraction.replace(/(0+)$/, '')
	// 	return `${negative ? '-' : ''}${integer || '0'}${
	// 	  fraction ? `.${fraction}` : ''
	// 	}`
	// }

	render() {
		const { transaction, classes } = this.props;
		if (transaction) {
			let baseUrl =
				window.location.protocol +
				'//' +
				window.location.hostname +
				':' +
				window.location.port;
			let directLink =
				baseUrl + '/?tab=transactions&transId=' + transaction.txhash;
			try {
				const _Temp =  transaction.write_set[1];
				const _TempRead = transaction.read_set[1];
				// console.log(transaction,'transaction')
				// 使用正则表达式匹配第一个=号到第一个,号之间的字符串
				const regex = /=([^,]+),/;
				let from = "";
				let to = "";
				let block_num = 0;
				if(_Temp){
					from = _Temp.set[2]?.value.match(regex)?.[1];
					to = _Temp.set[3]?.value.match(regex)?.[1];
				}
				if(_TempRead){
					for(let i = 0;i < _TempRead.set?.length;i++){
						if(_TempRead.set[i].key.match(regex)?.[1] == to){
							// block_num = _TempRead.set[i]?.version?.block_num?.low || 0;
						}
					}
				}
				this.state = {
					from: from,
					to: to,
					// block_num:block_num+1,
					amount:this.formatUnits(_Temp.set[4]?.value,10),
				};
			} catch (error) {
				console.log(error,'error')
			}
			return (
				<Modal>
					{modalClasses => (
						<div className={modalClasses.dialog}>
							<Card className={modalClasses.card}>
								<CardTitle className={modalClasses.title}>
									<FontAwesome name="list-alt" className={classes.listIcon} />
									Transaction Details
									<button
										type="button"
										onClick={this.handleClose}
										className={modalClasses.closeBtn}
									>
										<FontAwesome name="close" />
									</button>
								</CardTitle>
								<CardBody className={modalClasses.body}>
									<Table striped hover responsive className="table-striped">
										<tbody>
											<tr>
												<th>Transaction ID:</th>
												<td>
													{transaction.txhash}
													<button type="button" className={modalClasses.copyBtn}>
														<div className={modalClasses.copy}>Copy</div>
														<div className={modalClasses.copied}>Copied</div>
														<CopyToClipboard text={transaction.txhash}>
															<FontAwesome name="copy" />
														</CopyToClipboard>
													</button>
												</td>
											</tr>
											<tr>
												<th>validation code:</th>
												<td>
													{transaction.validation_code}
													<button type="button" className={modalClasses.copyBtn}>
														<div className={modalClasses.copy}>Copy</div>
														<div className={modalClasses.copied}>Copied</div>
														<CopyToClipboard text={transaction.validation_code}>
															<FontAwesome name="copy" />
														</CopyToClipboard>
													</button>
												</td>
											</tr>
											<tr>
												<th>From:</th>
												<td>
													{this.state.from}
													<button type="button" className={modalClasses.copyBtn}>
														<div className={modalClasses.copy}>Copy</div>
														<div className={modalClasses.copied}>Copied</div>
														<CopyToClipboard text={this.state.from}>
															<FontAwesome name="copy" />
														</CopyToClipboard>
													</button>
												</td>
											</tr>
											<tr>
												<th>To:</th>
												<td>
													{this.state.to}
													<button type="button" className={modalClasses.copyBtn}>
														<div className={modalClasses.copy}>Copy</div>
														<div className={modalClasses.copied}>Copied</div>
														<CopyToClipboard text={this.state.to}>
															<FontAwesome name="copy" />
														</CopyToClipboard>
													</button>
												</td>
											</tr>
											<tr>
												<th>Amount:</th>
												<td>
													{this.state.amount}
												</td>
											</tr>
											{/* <tr>
												<th>Block Number:</th>
												<td>
													{this.state.block_num}
												</td>
											</tr> */}
											<tr>
												<th>Time:</th>
												<td>{this.formatDate(transaction.createdt)}</td>
											</tr>
											{/* <tr>
												<th>Validation Code:</th>
												<td>{transaction.validation_code}</td>
											</tr>
											<tr>
												<th>Payload Proposal Hash:</th>
												<td>{transaction.payload_proposal_hash}</td>
											</tr>
											<tr>
												<th>Creator MSP:</th>
												<td>{transaction.creator_msp_id}</td>
											</tr>
											<tr>
												<th>Endorser:</th>
												<td>{transaction.endorser_msp_id}</td>
											</tr>
											<tr>
												<th>Chaincode Name:</th>
												<td>{transaction.chaincodename}</td>
											</tr>
											<tr>
												<th>Type:</th>
												<td>{transaction.type}</td>
											</tr>
											<tr>
												<th>Direct Link:</th>
												<td>
													{directLink}
													<button type="button" className={modalClasses.copyBtn}>
														<div className={modalClasses.copy}>Copy</div>
														<div className={modalClasses.copied}>Copied</div>
														<CopyToClipboard text={directLink}>
															<FontAwesome name="copy" />
														</CopyToClipboard>
													</button>
												</td>
											</tr> */}
											{/* <tr className={!transaction.read_set && classes.readset_null}>
												<th style={reads}>Reads:</th>
												<td className={classes.JSONtree}>
													<JSONTree
														data={transaction.read_set}
														theme={readTheme}
														invertTheme={false}
													/>
												</td>
											</tr>
											<tr className={!transaction.read_set && classes.readset_null}>
												<th style={writes}>Writes:</th>
												<td className={classes.JSONtree}>
													<JSONTree
														data={transaction.write_set}
														theme={writeTheme}
														invertTheme={false}
													/>
												</td>
											</tr> */}
										</tbody>
									</Table>
								</CardBody>
							</Card>
						</div>
					)}
				</Modal>
			);
		}
		return (
			<Modal>
				{modalClasses => (
					<div>
						<CardTitle className={modalClasses.title}>
							<FontAwesome name="list-alt" className={classes.listIcon} />
							Transaction Details
							<button
								type="button"
								onClick={this.handleClose}
								className={modalClasses.closeBtn}
							>
								<FontAwesome name="close" />
							</button>
						</CardTitle>
						<div align="center">
							<CardBody className={modalClasses.body}>
								<span>
									{' '}
									<FontAwesome name="circle-o-notch" size="3x" spin />
								</span>
							</CardBody>
						</div>
					</div>
				)}
			</Modal>
		);
	}
}

TransactionView.propTypes = {
	transaction: transactionType
};

TransactionView.defaultProps = {
	transaction: null
};

export default withStyles(styles)(TransactionView);
