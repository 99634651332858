/**
 *    SPDX-License-Identifier: Apache-2.0
 */

import React,{Component} from 'react';
import { withStyles } from '@material-ui/core/styles';
import Pagination from '@mui/material/Pagination';
import { Button } from 'reactstrap';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import ReactTable from '../../Styled/Table';
import MultiSelect from '../../Styled/MultiSelect';

import { get } from '../../../services/request';
const styles = (theme) => {
    const { type } = theme.palette;
    const dark = type === 'dark';
    return {
        content: {
            backgroundColor: dark ? '#3c3558' : undefined,
            '& .recharts-layer': {
                fill: dark ? 'rgb(42, 173, 230) !important' : '#5bc5c2 !important',
            },
            '& .recharts-scatter-line': {
                stroke: dark ? '#ffc145 !important' : '#5bc5c2 !important',
                strokeWidth: '2 !important',
            },
            '& .recharts-text': {
                fill: dark ? '#ffffff !important' : undefined,
            },
            '& .recharts-cartesian-axis-line': {
                stroke: dark ? '#ffffff' : undefined,
            },
            cubeIcon: {
                color: '#ffffff',
                marginRight: 20
            },
        },
		table: {
			// minHeight: 300,
			'& .rt-thead .rt-tr': {
				padding: '10px 0'
			},
			'& .rt-thead .rt-th': {
				color: 'rgb(102, 102, 102)',
				'font-family': 'PingFang SC',
				'font-size': '14px',
				'font-weight': '500',
			},
		},
		filter: {
			width: '100%',
			textAlign: 'center',
			margin: '15px 0 !important'
		},
		filterElement: {
			textAlign: 'center',
			display: 'flex',
			padding: '0px !important',
			alignItems: 'center',
			gap:'10px',
			'& .label': {
				margin: '0'
			}
		},
		filterDate: {
			'& > div': {
				flex:1,
				// width: '100% !important',
				// marginTop: 20
			}
		},
		searchButton: {
			margin: 'auto',
			width: '100% !important',
			backgroundColor: dark ? undefined : 'rgb(28, 185, 157)',
			// 'margin-bottom': '4px'
		},
    };
};
const tablePaginationStyle = {
	display: 'flex',
	justifyContent: 'center',
	padding: '0px 15px',
	alignItems: 'baseline',
	'.MuiToolbar-root': {
		alignItems: 'baseline'
	}
};
const _symbolList = [
	{label: "CJ", value: "CJ" },
	{label: "IBC", value: "INC" },
	{label: "MYC", value: "MYC" },
	{label: "TSR", value: "TSR" },
	{label: "CL", value: "CL" },
]
export class BalanceRankViews extends Component {
    constructor(props) {
		super(props);
		this.state = {
			symbol:[_symbolList[0].value],
			pageNo: 1,
			loading: false,
			dataList: [],
			pages:0,
		};
	}

	componentDidMount() {
		this.fetchData(this.state.pageNo,this.state.symbol[0]);
	}

	fetchData(pageNo,symbol) {
		this.setState({ loading: true });
		get(`/api/blocks/browser/getInfoWalletList?pageNo=${pageNo}&pageSize=10&assetCode=${symbol}`)
		.then(res => {
			this.setState({ dataList: res.data.data.list, loading: false,pages: res.data.data.pages });
		})
		.catch(error => {
			
		});
	}
    handlePageChange = (_e, page) => {
		this.setState({ pageNo: page });
		this.fetchData(page,this.state.symbol[0]);
	};


	handleMultiSelect = value => {
		const _symbol = value[value.length-1] || _symbolList[0].value;
		this.setState({ symbol: [_symbol] });
	};



    render(){
        const { classes } = this.props;
        const columnHeaders = [			
			{
				Header: '序号',
				Cell: row => {
					return  (
						<span>
							{row.index+1}
						</span>
					)
				},
			},
            {
				Header: '地址',
				accessor: 'address',
			},
            {
				Header: 'Token',
				accessor: 'assetCode',
			},
            {
				Header: '拥有',
				accessor: 'amount',
			},            
		];
		return (
			<div>
				<div className={`${classes.filter} row searchRow`}>
					<div className={`${classes.filterElement} ${classes.filterDate} col-md-3`}>
						<label className="label">积分</label>
						<MultiSelect
							hasSelectAll={false}
							shouldToggleOnHover={false}
							selected={this.state.symbol}
							options={_symbolList}
							onSelectedChanged={this.handleMultiSelect}
						/>
					</div>
					<div className="col-md-2" style={{margin: '0'}}>
						<Button
							className={classes.searchButton}
							color="success"
							onClick={() => {
								this.setState({ pageNo: 1 });
								this.fetchData(1,this.state.symbol[0]);
							}}
						>
							Search
						</Button>
					</div>
				</div>
				<Card>
					<CardContent className={classes.content}>
						<ReactTable
							loading={this.state.loading}
							className={classes.table}
							data={this.state.dataList}
							columns={columnHeaders}
							defaultPageSize={5}
							filterable={false}
							minRows={3}
							pageSize={10}
							showPagination={false}
						/>
						<Pagination
							count={this.state.pages}
							defaultPage={1}
							page={this.state.pageNo}
							sx={tablePaginationStyle}
							color="primary"
							onChange={this.handlePageChange}
						/>
					</CardContent>
				</Card>
			</div>
		)
    }
}

export default withStyles(styles)(BalanceRankViews);
