/**
 *    SPDX-License-Identifier: Apache-2.0
 */

import React from 'react';
import View from '../Styled/View';
import Peers from '../Lists/Peers';
import { peerListType } from '../types';
// import { withStyles } from '@material-ui/core/styles';


export const NetworkView = ({ peerList,classes }) => (
  <View>
    <Peers peerList={peerList} classes={classes}/>
  </View>
);

NetworkView.propTypes = {
  peerList: peerListType.isRequired,
};

export default NetworkView;