/**
 *    SPDX-License-Identifier: Apache-2.0
 */

import React,{Component} from 'react';
import { withStyles } from '@material-ui/core/styles';
import Pagination from '@mui/material/Pagination';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import ReactTable from '../../Styled/Table';
import { Button } from 'reactstrap';
import { get } from '../../../services/request';
const styles = (theme) => {
    const { type } = theme.palette;
    const dark = type === 'dark';
    return {
        content: {
            backgroundColor: dark ? '#3c3558' : undefined,
            '& .recharts-layer': {
                fill: dark ? 'rgb(42, 173, 230) !important' : '#5bc5c2 !important',
            },
            '& .recharts-scatter-line': {
                stroke: dark ? '#ffc145 !important' : '#5bc5c2 !important',
                strokeWidth: '2 !important',
            },
            '& .recharts-text': {
                fill: dark ? '#ffffff !important' : undefined,
            },
            '& .recharts-cartesian-axis-line': {
                stroke: dark ? '#ffffff' : undefined,
            },
            cubeIcon: {
                color: '#ffffff',
                marginRight: 20
            },
        },
		table: {
			// minHeight: 300,
			'& .rt-thead .rt-tr': {
				padding: '10px 0'
			},
			'& .rt-thead .rt-th': {
				color: 'rgb(102, 102, 102)',
				'font-family': 'PingFang SC',
				'font-size': '14px',
				'font-weight': '500',
			},
		},
		filter: {
			width: '100%',
			textAlign: 'center',
			margin: '15px 0 !important'
		},
		filterElement: {
			textAlign: 'center',
			display: 'flex',
			padding: '0px !important',
			alignItems: 'center',
			gap:'10px',
			'& .label': {
				margin: '0'
			}
		},
		filterDate: {
			'& > div': {
				flex:1,
			}
		},
		searchButton: {
			margin: 'auto',
			width: '100% !important',
			backgroundColor: dark ? undefined : 'rgb(28, 185, 157)',
		},
    };
};
const tablePaginationStyle = {
	display: 'flex',
	justifyContent: 'center',
	padding: '0px 15px',
	alignItems: 'baseline',
	'.MuiToolbar-root': {
		alignItems: 'baseline'
	}
};
export class ContractViews extends Component {
    constructor(props) {
		super(props);
		this.state = {
			pageNo: 1,
			loading: false,
			dataList: [],
			pages:0,
		};
	}

	componentDidMount() {
		this.fetchData(this.state.pageNo);
	}

	fetchData(pageNo) {
		this.setState({ loading: true });
		get(`/api/blocks/browser/getContractDetailList?pageNo=${pageNo}&pageSize=10`)
		.then(res => {
			this.setState({ dataList: res.data.data.list, loading: false,pages: res.data.data.pages });
		})
		.catch(error => {
			
		});
	}
    handlePageChange = (_e, page) => {
		this.setState({ pageNo: page });
		this.fetchData(page);
	};

	formatDate(isoString) {
		const date = new Date(isoString);
		const year = date.getFullYear();
		const month = String(date.getMonth() + 1).padStart(2, '0');
		const day = String(date.getDate()).padStart(2, '0');
		const hours = String(date.getHours()).padStart(2, '0');
		const minutes = String(date.getMinutes()).padStart(2, '0');
		const seconds = String(date.getSeconds()).padStart(2, '0');

		return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
	}

    render(){
        const { classes } = this.props;
        const columnHeaders = [			
			{
				Header: '序号',
				Cell: row => {
					return  (
						<span>
							{row.index+1}
						</span>
					)
				},
			},
			{
				Header: '合约名称',
				accessor: 'name',
			},
            {
				Header: '发布时间',
				accessor: 'ctime',
				Cell: row => (
					<span>
						{this.formatDate(row.value)}
					</span>
				),
			},
            {
				Header: '合约地址',
				accessor: 'contractAddress',
			},
            {
				Header: '合约版本',
				accessor: 'version',
			},
		];
		return (
			<div>
				<div className={`${classes.filter} row searchRow`}>
					<div className="col-md-2" style={{margin: '0'}}>
						<Button
							className={classes.searchButton}
							color="success"
							onClick={() => {
								this.setState({ pageNo: 1 });
								this.fetchData(1);
							}}
						>
							Search
						</Button>
					</div>
				</div>
				<Card>
					<CardContent className={classes.content}>
						<ReactTable
							loading={this.state.loading}
							className={classes.table}
							data={this.state.dataList}
							columns={columnHeaders}
							defaultPageSize={5}
							filterable={false}
							minRows={3}
							pageSize={10}
							showPagination={false}
						/>
						<Pagination
							count={this.state.pages}
							defaultPage={1}
							page={this.state.pageNo}
							sx={tablePaginationStyle}
							color="primary"
							onChange={this.handlePageChange}
						/>
					</CardContent>
				</Card>
			</div>
		)
    }
}

export default withStyles(styles)(ContractViews);
