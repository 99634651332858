/**
 *    SPDX-License-Identifier: Apache-2.0
 */

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import logo from "../../static/images/logo2.png"
import wechatQrcode from "../../static/images/wechat_qrcode.png";
import appletQrcode from "../../static/images/applet_qrcode .png";
const styles = theme => {
	const { type } = theme.palette;
	const dark = type === 'dark';
	return {
		footerRoot: {
			backgroundColor: '#181818',
		},
		footer: {
			// display: 'flex',
			// justifyContent: 'space-around',
			width: '100vw',
			maxWidth: '1920px',
			padding: '20px 0',
			margin: '0 auto',
			// 'flex-wrap': 'wrap',
		},
		siteRecord: {
			textDecoration: 'none',
			outline: 'none',
			color: '#fff',
			fontFamily: 'PingFang SC',
			fontSize: '12px',
			fontWeight: '400',
			lineHeight: '20px',
			display:'block',
			textAlign:'center',
		},
		brand:{
			color: '#fff',
			fontFamily: 'PingFang SC',
			fontSize: '15px',
			fontWeight: '400',
			lineHeight: '25px',
			textAlign:'center',
		}
	};
};

const FooterView = ({ classes }) => (
	<div className={classes.footerRoot}>
		<div className={classes.footer}>
			<p className={classes.brand}>海南秒链科技有限公司</p>
			<a className={classes.siteRecord} href="http://beian.miit.gov.cn/ ">琼ICP备2023001370号-1</a>
		</div>
	</div>
);

export default withStyles(styles)(FooterView);
