/**
 *    SPDX-License-Identifier: Apache-2.0
 */

import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Card from '@material-ui/core/Card';
import moment from 'moment-timezone';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import { chartSelectors, chartOperations } from '../../../state/redux/charts';
import BlockViews from "./BlockViews.js";
import TokenViews from "./TokenViews.js";
import TXViews from "./TXViews.js"
import ContractViews from './ContractViews.js';
import BalanceRankViews from "./BalanceRankViews.js";
import TXFreqRankViews from "./TXFreqRankViews.js";
import {
	blockPerHourType,
	blockPerMinType,
	currentChannelType,
	getBlocksPerHourType,
	getBlocksPerMinType,
	getTransactionPerHourType,
	getTransactionPerMinType,
	transactionPerHourType,
	transactionPerMinType
} from '../../types';

const {
	blockPerHourSelector,
	blockPerMinSelector,
	currentChannelSelector,
	transactionPerHourSelector,
	transactionPerMinSelector
} = chartSelectors;

/* istanbul ignore next */
const styles = theme => {
	const { type } = theme.palette;
	const dark = type === 'dark';
	return {
		chart: {
			width:'100%',
			margin:'20px auto',
			color: dark ? '#ffffff' : undefined,
			backgroundColor: dark ? '#453e68' : undefined
		},
		active:{
			'border-radius': '10px 0px 0px 0px',
			'box-shadow': '0px 7px 27px 0px rgba(0, 10, 23, 0.06)',
			'background-color': 'rgb(54, 202, 132) !important',
			'color':'#ffffff !important',
		}
	};
};

export class ChartStats extends Component {
	constructor(props) {
		super(props);
		this.state = {
			activeTab: '1'
		};
	}

	componentDidMount() {
		this.interVal = setInterval(() => {
			const { currentChannel } = this.props;
			this.syncData(currentChannel);
		}, 60000);
	}

	componentWillUnmount() {
		clearInterval(this.interVal);
	}

	syncData = currentChannel => {
		const {
			getBlocksPerHour,
			getBlocksPerMin,
			getTransactionPerHour,
			getTransactionPerMin
		} = this.props;

		getBlocksPerMin(currentChannel);
		getBlocksPerHour(currentChannel);
		getTransactionPerMin(currentChannel);
		getTransactionPerHour(currentChannel);
	};

	timeDataSetup = (chartData = []) => {
		let dataMax = 0;
		const displayData = chartData.map(data => {
			if (parseInt(data.count, 10) > dataMax) {
				dataMax = parseInt(data.count, 10);
			}

			return {
				datetime: moment(data.datetime)
					.tz(moment.tz.guess())
					.format('h:mm A'),
				count: data.count
			};
		});

		dataMax += 5;

		return {
			displayData,
			dataMax
		};
	};

	toggle = tab => {
		this.setState({
			activeTab: tab
		});
	};

	render() {
		const { activeTab } = this.state;
		const {
			blockPerHour,
			blockPerMin,
			transactionPerHour,
			transactionPerMin,
			classes
		} = this.props;

		return (
			<div className={classes.chart}>
				<Card>

					<Nav tabs>
						<NavItem>
							<NavLink
								style={{color:'#000'}}
								className={classnames({
									active: activeTab === '1',
									[classes.active]:activeTab === '1',
								})}
								onClick={() => {
									this.toggle('1');
								}}
							>
								区块
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink
								style={{color:'#000'}}
								className={classnames({
									active: activeTab === '2',
									[classes.active]:activeTab === '2',
								})}
								onClick={() => {
									this.toggle('2');
								}}
							>
								Token
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink
								style={{color:'#000'}}
								className={classnames({
									active: activeTab === '3',
									[classes.active]:activeTab === '3',
								})}
								onClick={() => {
									this.toggle('3');
								}}
							>
								交易
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink
								style={{color:'#000'}}
								className={classnames({
									active: activeTab === '4',
									[classes.active]:activeTab === '4',
								})}
								onClick={() => {
									this.toggle('4');
								}}
							>
								合约
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink
								style={{color:'#000'}}
								className={classnames({
									active: activeTab === '5',
									[classes.active]:activeTab === '5',
								})}
								onClick={() => {
									this.toggle('5');
								}}
							>
								积分数量排行
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink
								style={{color:'#000'}}
								className={classnames({
									active: activeTab === '6',
									[classes.active]:activeTab === '6',
								})}
								onClick={() => {
									this.toggle('6');
								}}
							>
								交易频率排行
							</NavLink>
						</NavItem>
					</Nav>
					<TabContent activeTab={activeTab} style={{padding:'0 20px'}}>
						<TabPane tabId="1">
							<BlockViews currentChannel={this.props.currentChannel}></BlockViews>
						</TabPane>
						<TabPane tabId="2">
							<TokenViews></TokenViews>
						</TabPane>
						<TabPane tabId="3">
							<TXViews transaction={this.props.txnList} getTxnList={this.props.getTxnList} currentChannel={this.props.currentChannel}></TXViews>
						</TabPane>
						<TabPane tabId="4">
							<ContractViews></ContractViews>
						</TabPane>
						<TabPane tabId="5">
							<BalanceRankViews></BalanceRankViews>
						</TabPane>
						<TabPane tabId="6">
							<TXFreqRankViews></TXFreqRankViews>
						</TabPane>
					</TabContent>
				</Card>
			</div>
		);
	}
}

ChartStats.propTypes = {
	blockPerHour: blockPerHourType.isRequired,
	blockPerMin: blockPerMinType.isRequired,
	currentChannel: currentChannelType.isRequired,
	getBlocksPerHour: getBlocksPerHourType.isRequired,
	getBlocksPerMin: getBlocksPerMinType.isRequired,
	getTransactionPerHour: getTransactionPerHourType.isRequired,
	getTransactionPerMin: getTransactionPerMinType.isRequired,
	transactionPerHour: transactionPerHourType.isRequired,
	transactionPerMin: transactionPerMinType.isRequired
};

const mapStateToProps = state => {
	return {
		blockPerHour: blockPerHourSelector(state),
		blockPerMin: blockPerMinSelector(state),
		transactionPerHour: transactionPerHourSelector(state),
		transactionPerMin: transactionPerMinSelector(state),
		currentChannel: currentChannelSelector(state)
	};
};

const mapDispatchToProps = {
	getBlocksPerHour: chartOperations.blockPerHour,
	getBlocksPerMin: chartOperations.blockPerMin,
	getTransactionPerHour: chartOperations.transactionPerHour,
	getTransactionPerMin: chartOperations.transactionPerMin
};

const connectedComponent = connect(
	mapStateToProps,
	mapDispatchToProps
)(ChartStats);
export default withStyles(styles)(connectedComponent);
