/**
 *    SPDX-License-Identifier: Apache-2.0
 */

import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Row, Col } from 'reactstrap';
import FontAwesome from 'react-fontawesome';
import Card from '@material-ui/core/Card';
import Avatar from '@material-ui/core/Avatar';
import ChartStats from '../Charts/ChartStats';
import PeersHealth from '../Lists/PeersHealth';
import TimelineStream from '../Lists/TimelineStream';
import OrgPieChart from '../Charts/OrgPieChart';
import Banner from '../../static/images/banner.png';
import ItemIcon from "../../static/images/item-icon.png";
import contactBg from "../../static/images/contactBg.png";
import contactIcon from "../../static/images/contact-icon.png"
import bg from "../../static/images/bg.png"
import timeline_bg from "../../static/images/timeline_bg.png"
import pie_bg from "../../static/images/pie_bg.png"
import Dashboard from "./Dashboard/Dashboard.js"
import {
	blockListSearchType,
	dashStatsType,
	peerListType,
	txnListType,
	blockSearchType,
	transactionByOrgType
} from '../types';
import SearchByQuery from '../Lists/SearchByQuery';
import { connect } from 'react-redux';
import { currentChannelSelector } from '../../state/redux/charts/selectors';
import { tableOperations } from '../../state/redux/tables';

const { txnList, blockSearch } = tableOperations;

/* istanbul ignore next */
const styles = theme => {
	const { type } = theme.palette;
	const dark = type === 'dark';
	return {
		background: {
			backgroundColor: dark ? 'rgb(36, 32, 54)' : '#f0f5f9'
		},
		view: {
			paddingTop: 85,
			paddingLeft: 0,
			width: '100%',
			// margin: '0 auto',
			// maxWidth: '1400px',
			// marginLeft: '10%',
			// marginRight: '10%'
		},
		dashboardSearch: {
			position: 'absolute',
			width: '80%'
		},
		search: {
			marginLeft: '10px'
		},
		blocks: {
			// [theme.breakpoints.only('xs')]: {
			// 	height: '175px !important'
			// },
			// [theme.breakpoints.down('md')]: {
			// 	height: 145
			// },
			// [theme.breakpoints.up('md')]: {
			// 	height: 175
			// },
			// [theme.breakpoints.up('lg')]: {
			// 	height: 185
			// },
			// minWidth: 280,
			marginBottom: 20,
			'box-shadow': 'unset',
			backgroundColor: 'transparent',
			display: 'flex',
			'flex-wrap': 'wrap',
			'justify-content': "flex-start",
			'gap': '8px',
			// paddingBottom: 20,
			// backgroundColor: dark ? '#453e68' : '#ffffff',
			// boxShadow: dark ? '1px 2px 2px rgb(215, 247, 247)' : undefined
		},
		count: {
			color: dark ? '#ffffff' : undefined,
			[theme.breakpoints.only('xs')]: {
				// marginTop: '10% !important'
			},
			[theme.breakpoints.up('xs')]: {
				// marginTop: '55%'
			}
		},
		totalItemTitle: {
			color: 'rgb(153, 153, 153)',
			'font-family': 'PingFang SC',
			'font-size': '20px',
			'font-weight': '500',
		},
		statistic: {
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			display: 'flex',
			float: 'left',
			height: '100%',
			width: '330px',
			textAlign: 'center',
			color: dark ? '#ffffff' : '#000000',
			[theme.breakpoints.only('xs')]: {
				fontSize: '8pt !important'
			},
			[theme.breakpoints.down('md')]: {
				fontSize: '12pt'
			},
			[theme.breakpoints.up('md')]: {
				fontSize: '18pt'
			}
		},
		totalItem: {
			width: '100%', flexWrap: 'wrap-reverse', margin: '0', alignItems: 'center',
			'border-radius': '10px',
			background: 'rgb(255, 255, 255)',
			padding: '30px 0',
		},
		vdivide: {
			// '&::after': {
			// 	borderRight: `2px ${dark ? 'rgb(40, 36, 61)' : '#dff1fe'} solid`,
			// 	display: 'block',
			// 	height: '45%',
			// 	bottom: '55%',
			// 	content: "' '",
			// 	position: 'relative'
			// }
		},
		avatar: {
			justifyContent: 'center',
			[theme.breakpoints.only('xs')]: {
				marginLeft: '35% !important',
				marginTop: '25% !important'
			},
			[theme.breakpoints.up('xs')]: {
				marginLeft: '60%',
				marginTop: '65%'
			}
		},
		node: {
			color: dark ? '#183a37' : '#21295c',
			backgroundColor: dark ? 'rgb(104, 247, 235)' : '#858aa6'
		},
		block: {
			color: dark ? '#1f1a33' : '#004d6b',
			backgroundColor: dark ? 'rgb(106, 156, 248)' : '#b9d6e1'
		},
		chaincode: {
			color: dark ? 'rgb(121, 83, 109)' : '#407b20',
			backgroundColor: dark ? 'rgb(247, 205, 234)' : '#d0ecda'
		},
		transaction: {
			color: dark ? 'rgb(216, 142, 4)' : '#ffa686',
			backgroundColor: dark ? 'rgb(252, 224, 174)' : '#ffeed8'
		},
		section: {
			minWidth: 280,
			height: 335,
			marginBottom: '2%',
			color: dark ? '#ffffff' : undefined,
			backgroundColor: dark ? '#3c3558' : undefined
		},
		center: {
			textAlign: 'center'
		},
		pie: {
			// [theme.breakpoints.up('md')]: {
			// 	marginLeft: '25%'
			// }
		},
		contactWrap: {
			marginBottom: '39px',
			position: 'relative',
		},
		orgpiechart: {
			position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)',
			[theme.breakpoints.down('sm')]: {
				width: '80%',
			},
		},
		orgpiechartTitle: {
			[theme.breakpoints.down('sm')]: {
				display: 'none',
			},
		},
		phone:{
			'color': 'rgb(255, 255, 255)', 'font-family': 'DIN Next LT Pro', 'font-size': '26px', 'font-weight': '700','margin-bottom': '0px',
			[theme.breakpoints.down('sm')]: {
				'font-size': '15px', 'margin-bottom': '0px','font-weight': 'normal',
			},
		},
		contactText:{
			'color': 'rgb(255, 255, 255)', 'font-family': 'DIN Next LT Pro', 'font-size': '14px', 'font-weight': '400','margin-bottom': '0px',
			[theme.breakpoints.down('sm')]: {
				'font-size': '14px', 'margin-bottom': '0px','font-weight': 'normal','display': 'none',
			},
		},
	};
};

export class DashboardView extends Component {
	constructor(props) {
		super(props);
		this.state = {
			notifications: [],
			hasDbError: false
		};
	}

	componentWillMount() {
		const {
			blockListSearch,
			dashStats,
			peerList,
			transactionByOrg,
			blockActivity
		} = this.props;
		if (
			blockListSearch === undefined ||
			dashStats === undefined ||
			peerList === undefined ||
			blockActivity === undefined ||
			transactionByOrg === undefined
		) {
			this.setState({ hasDbError: true });
		}
	}

	componentDidMount() {
		const { blockActivity } = this.props;
		this.setNotifications(blockActivity);
	}

	componentWillReceiveProps() {
		const { blockActivity } = this.props;
		this.setNotifications(blockActivity);
	}

	setNotifications = blockList => {
		const notificationsArr = [];
		if (blockList !== undefined) {
			for (let i = 0; i < 3 && blockList && blockList[i]; i += 1) {
				const block = blockList[i];
				const notify = {
					title: `Block ${block.blocknum} `,
					type: 'block',
					time: block.createdt,
					txcount: block.txcount,
					datahash: block.datahash,
					blockhash: block.blockhash,
					channelName: block.channelname
				};
				notificationsArr.push(notify);
			}
		}
		this.setState({ notifications: notificationsArr });
	};

	render() {
		const {
			dashStats,
			peerList,
			txnList,
			blockSearch,
			blockActivity,
			transactionByOrg
		} = this.props;
		const { hasDbError, notifications } = this.state;
		var searchError = '';
		if (typeof txnList === 'string') {
			searchError = 'Txn not found';
		} else if (typeof blockSearch === 'string') {
			searchError = 'Block not found';
		}
		if (hasDbError) {
			return (
				<div
					style={{
						height: '100vh',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center'
					}}
				>
					<h1>
						Please verify your network configuration, database configuration and try
						again
					</h1>
				</div>
			);
		}
		const { classes } = this.props;
		return (
			<div className={classes.background}>
				<img src={Banner} style={{ width: "100%" }} />
				<div className={classes.view}>
					<Row style={{ margin: '0 auto', maxWidth: '1400px' }}>
						<Col sm="12" md="6" style={{ margin: '10px auto 0px' }}>
							<SearchByQuery
								getTxnList={this.props.getTxnList}
								getBlockSearch={this.props.getBlockSearch}
								currentChannel={this.props.currentChannel}
								txnList={txnList}
								blockSearch={blockSearch}
								searchError={searchError}
							/>
						</Col>
					</Row>
					<Row style={{ margin: '0 auto', maxWidth: '1400px' }}>
						<Col sm="12">
							<Card className={classes.blocks}>
								<div className={`${classes.statistic} ${classes.vdivide}`}>
									<Row className={classes.totalItem}>
										<Col sm="6" style={{ padding: '0' }}>
											<h1 className={classes.count}>{dashStats.latestBlock}</h1>
											<span className={classes.totalItemTitle}>BLOCKS</span>
										</Col>
										<Col sm="6" style={{ padding: '0' }}>
											<img src={ItemIcon} style={{ width: "101px" }}></img>
										</Col>
									</Row>
								</div>
								<div className={`${classes.statistic} ${classes.vdivide}`}>
									<Row className={classes.totalItem}>
										<Col sm="6" style={{ padding: '0' }}>
											<h1 className={classes.count}>{dashStats.txCount}</h1>
											<span className={classes.totalItemTitle}>TRANSACTIONS</span>
										</Col>
										<Col sm="6" style={{ padding: '0' }}>
											<img src={ItemIcon} style={{ width: "101px" }}></img>
										</Col>
									</Row>
								</div>
								<div className={`${classes.statistic} ${classes.vdivide}`}>
									<Row className={classes.totalItem}>
										<Col sm="6" style={{ padding: '0' }}>
											<h1 className={classes.count}>{dashStats.peerCount}</h1>
											<span className={classes.totalItemTitle}>NODES</span>
										</Col>
										<Col sm="6" style={{ padding: '0' }}>
											<img src={ItemIcon} style={{ width: "101px" }}></img>
										</Col>
									</Row>
								</div>
								<div className={classes.statistic}>
									<Row className={classes.totalItem}>
										<Col sm="6" style={{ padding: '0' }}>
											<h1 className={classes.count}>{dashStats.chaincodeCount}</h1>
											<span className={classes.totalItemTitle}>CHAINCODES</span>
										</Col>
										<Col sm="6" style={{ padding: '0' }}>
											<img src={ItemIcon} style={{ width: "101px" }}></img>
										</Col>
									</Row>
								</div>
							</Card>
						</Col>
					</Row>
					<Row style={{ margin: '0 auto', maxWidth: '1400px' }}>
						<Col sm="6">
							<div className={classes.contactWrap}>
								<img src={contactBg} style={{ width: '100%' }}></img>
								<div style={{ position: 'absolute', top: '50%', left: '25px', display: 'flex', 'align-items': 'center', 'column-gap': '25px', transform: 'translateY(-50%)' }}>
									<img src={contactIcon} style={{ width: '51px' }}></img>
									<div style={{display: 'flex', 'flex-direction': 'column', 'align-items': 'flex-start',justifyContent:'space-around'}}>
										<p className={classes.phone}>
											0898-68561913（海南）
										</p>
										<p className={classes.phone}>
											0898-68561916（海南）
										</p>
										<p className={classes.contactText}>
											CONTACT US
										</p>
									</div>
								</div>
							</div>
							<Card className={classes.section} style={{ height: '425px' }}>
								<PeersHealth peerStatus={peerList} />
							</Card>
							{/* <Card className={classes.section}>
								<TimelineStream
									notifications={notifications}
									blockList={blockActivity}
								/>
							</Card> */}
						</Col>
						<Col sm="6">
							<Card className={classes.section}>
								<ChartStats />
							</Card>
							<Card className={classes.section} style={{ height: 'auto' }}>
								<img src={bg} style={{ width: '100%' }} />
							</Card>
							{/* <Card className={`${classes.section} ${classes.center}`}>
								<h5>Transactions by Organization</h5>
								<hr />
								<div className={classes.pie}>
									<OrgPieChart transactionByOrg={transactionByOrg} />
								</div>
							</Card> */}
						</Col>
					</Row>
					<Row style={{ margin: '0 auto', maxWidth: '1400px' }}>
						<Card className={classes.section} style={{ width: '100%', height: '628px', border: '2px solid rgb(28, 185, 157)', position: 'relative', zIndex: '1' }}>
							<Col sm="8">
								<TimelineStream
									notifications={notifications}
									blockList={blockActivity}
								/>
							</Col>
							<img src={timeline_bg} style={{ maxWidth: '862px', position: 'absolute', right: '0', top: '0', zIndex: '-1' }} />
						</Card>
					</Row>
					<Row style={{ margin: '0 auto',maxWidth: '1400px' }}>
						<Col sm="12">
							<Dashboard txnList={txnList} getTxnList={this.props.getTxnList}></Dashboard>
						</Col>
					</Row>
					<Row>
						{/* ,position:'relative',zIndex:'1',display:'flex','align-items': 'center','justify-content': 'center' */}
						<Card className={classes.section} style={{ width: '100%', maxWidth: '1920px', height: 'auto', border: 'unset', position: 'relative', boxShadow: 'unset', margin: '0 auto' }}>
							{/* style={{position:'absolute',top:'50%',left:'50%',transform:'translate(-50%, -50%)'}} */}
							<div className={classes.orgpiechart}>
								<p className={classes.orgpiechartTitle} style={{ color: 'rgb(0, 0, 0)', 'font-size': '40px', 'font-weight': '800', }}>
									Transactions by Organization
								</p>
								<p className={classes.orgpiechartTitle} style={{ color: 'rgb(204, 204, 204)', 'font-size': '26px', 'font-weight': '800', }}>
									Content details
								</p>
								<div style={{ width: '100%', maxWidth: '800px', }}>
									<div className={classes.pie}>
										<OrgPieChart transactionByOrg={transactionByOrg} />
									</div>
								</div>
							</div>
							{/* <img src={pie_bg} style={{width: '100%', height: 'auto', position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', zIndex: '-1'}} /> */}
							<img src={pie_bg} style={{ width: '100%' }} />
						</Card>
					</Row>
				</div>
			</div>
		);
	}
}

DashboardView.propTypes = {
	blockListSearch: blockListSearchType.isRequired,
	dashStats: dashStatsType.isRequired,
	peerList: peerListType.isRequired,
	txnList: txnListType.isRequired,
	blockSearch: blockSearchType.isRequired,
	transactionByOrg: transactionByOrgType.isRequired
};

const mapStateToProps = state => {
	return {
		currentChannel: currentChannelSelector(state)
	};
};
const mapDispatchToProps = {
	getTxnList: txnList,
	getBlockSearch: blockSearch
};
const connectedComponent = connect(
	mapStateToProps,
	mapDispatchToProps
)(DashboardView);
export default withStyles(styles)(connectedComponent);
